/**
 * An abstract class that contains the generic CRUD functions for any app CRUD service. Each function can be overwrite if an specific
 * code is needed.
 * @author Davi Leal
 *
 * Copyright 2019 Finstein GmbH - All Rights Reserved.
 */

import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FIREBASE_URL_FUNCTIONS, STATUS_INACTIVE } from '../../app.constants';
import { FilterModel } from '../../shared/models/filter.model';
import { FunctionOptions } from '../domain/function-options';
import { FireLoggingService } from '../services/fire-logging.service';

@Injectable({
    providedIn: 'root'
})
export class BaseService<T> {

    constructor(protected firestore: AngularFirestore,
                protected http: HttpClient,
                protected functions: Functions,
                protected fireLogging: FireLoggingService) {
    }

    /**
     * Create a data into firestore.
     * @param entity to be persisted
     * @param path (collection name or function name)
     * @param functionsOpts object that represents the request. If that object is filled, the functions will be called passing the
     * properties inside it.
     */
    public create(entity: T, path: string, functionsOpts?: FunctionOptions): Observable<any> {
        if (functionsOpts) {
            return this.callFunction(entity, path, functionsOpts);
        } else {
            return new Observable((observer) => {
                this.firestore.collection(path).add(entity).then(() => observer.next()).catch((err) => observer.error(err));
            }).pipe(
                catchError((error) => {
                    const message = error.error ? error.error.message : error.message;
                    if (functionsOpts) {
                        this.fireLogging.sendErrorLog(`An error occurred while inserting item with function ${ path }, details: ${ message || JSON.stringify(error) }`);
                    } else {
                        this.fireLogging.sendErrorLog(`An error occurred while creating item into collection ${ path }, details: ${ message || JSON.stringify(error) }`);
                    }
                    throw {error};
                })
            );
        }
    }

    /**
     * Update a data into firestore by doc ID.
     * @param id to get the specific document
     * @param entity to be persisted
     * @param path (collection name or function name)
     * @param functionsOpts object that represents the request. If that object is filled, the functions will be called passing the
     * properties inside it.
     */
    public update(id: string, entity: T, path: string, functionsOpts?: FunctionOptions): Observable<any> {
        if (functionsOpts) {
            return this.callFunction({id, ...entity}, path, functionsOpts);
        } else {
            return new Observable((observer) => {
                this.firestore.collection(path).doc(id).set(entity).then(() => observer.next())
                    .catch((err) => observer.error(err));
            }).pipe(
                catchError((error) => {
                    const message = error.error ? error.error.message : error.message;
                    if (functionsOpts) {
                        this.fireLogging.sendErrorLog(`An error occurred while updating item with function ${ path }, details: ${ message || JSON.stringify(error) }`);
                    } else {
                        this.fireLogging.sendErrorLog(`An error occurred while updating item into collection ${ path }, details: ${ message || JSON.stringify(error) }`);
                    }
                    throw {error};
                })
            );
        }
    }

    /**
     * Get all the documents of a collection. This data can be retrieved by AngularFirestore or functions.
     * @param path Collection or function name
     * @param filter Object containing all the filter configuration
     * @param functionsOpts The function options
     */
    public search(path: string, filter?: FilterModel, functionsOpts?: FunctionOptions): Observable<any> {
        filter = filter ? filter : new FilterModel();
        if (functionsOpts) {
            return this.callFunction(null, path, functionsOpts);
        } else {
            return this.firestore.collection(path, (ref) => {
                return this.buildFirebaseFilter(ref, filter);
            }).snapshotChanges()
                .pipe(
                    map(actions => actions.map(action => {
                            const data: any = action.payload.doc.data();
                            data.id = action.payload.doc.id;
                            return data;
                        })
                    ),
                    catchError((error) => {
                        const message = error.error ? error.error.message : error.message;
                        if (functionsOpts) {
                            this.fireLogging.sendErrorLog(`An error occurred while fetching the collection ${ path } using the filter ${ JSON.stringify(filter) }, details: ${ message || JSON.stringify(error) }`);
                        } else {
                            this.fireLogging.sendErrorLog(`An error occurred while fetching the collection ${ path } using the filter ${ JSON.stringify(filter) }, details: ${ message || JSON.stringify(error) }`);
                        }
                        throw {error};
                    })
                );
        }
    }

    /**
     * Count the quantity of document in the specific collection.
     * @param path Collection or function name
     * @param filter Object containing all the filter configuration
     */
    public count(path: string, filter?: FilterModel): Observable<{ count: number, cursors: any[] } | any> {
        filter = filter ? filter : new FilterModel();
        return this.firestore.collection(path, (ref: any) => {
            ref = this.buildFirebaseFiltersClauses(ref, filter);
            ref = this.buidlFirebaseOrderFilter(ref, filter);
            if (filter.search) {
                ref = ref.startAt(filter.search).endAt(`${ filter.search }\uf8ff`);
            }
            return ref;
        }).get().pipe(
            map(res => {
                const cursors = [];
                for (let index = (filter.pageSize - 1); index < res.docs.length; index += filter.pageSize) {
                    const element = res.docs[index];
                    cursors.push(element);
                }
                return {count: res.size, cursors};
            }),
            catchError((error) => {
                const message = error.error ? error.error.message : error.message;
                this.fireLogging.sendErrorLog(`An error occurred while getting the count of items in the collection ${ path }, details: ${ message || JSON.stringify(error) }`);
                throw {error};
            })
        );
    }

    /**
     * Disable/enable a data by its ID.
     * @param id of the document to be updated
     * @param path collection or function name
     * @param functionsOpts object that represents the request. If that object is filled, the functions will be called passing the
     * properties inside it.
     */
    public remove(path: string, id: string, functionsOpts?: FunctionOptions): Observable<any> {
        if (functionsOpts) {
            return this.callFunction({id}, path, functionsOpts);
        } else {
            return new Observable((observer) => {
                this.firestore.collection(path).doc(id).update({status: STATUS_INACTIVE}).then(() => observer.next())
                    .catch(err => observer.error(err));
            }).pipe(
                catchError((error) => {
                    const message = error.error ? error.error.message : error.message;
                    if (functionsOpts) {
                        this.fireLogging.sendErrorLog(`An error occurred while removing item with function ${ path }, details: ${ message || JSON.stringify(error) }`);
                    } else {
                        this.fireLogging.sendErrorLog(`An error occurred while removing item into collection ${ path }, details: ${ message || JSON.stringify(error) }`);
                    }
                    throw {error};
                })
            );
        }
    }

    /**
     * Get a specific document by ID. This data can be retrieved by AngularFirestore or functions.
     * @param id of the document to be updated
     * @param path collection or function name
     * @param functionsOpts The function options
     */
    public getById(id: string, path: string, functionsOpts?: FunctionOptions): Observable<any> {
        if (functionsOpts) {
            return this.callFunction(null, path, functionsOpts);
        } else {
            return this.firestore.collection(path).doc(id).valueChanges().pipe(
                map((entity: any) => {
                    return entity;
                }),
                catchError((error) => {
                    const message = error.error ? error.error.message : error.message;
                    if (functionsOpts) {
                        this.fireLogging.sendErrorLog(`An error occurred while getting the item by ID with function ${ path }, details: ${ message || JSON.stringify(error) }`);
                    } else {
                        this.fireLogging.sendErrorLog(`An error occurred while getting the item by ID into collection ${ path }, details: ${ message || JSON.stringify(error) }`);
                    }
                    throw {error};
                })
            );
        }
    }

    protected buildFirebaseFiltersClauses(ref, filter: FilterModel) {
        if (filter.clauses) {
            filter.clauses.forEach((clause) => {
                ref = ref.where(clause.fieldPath, clause.opStr, clause.value);
            });
        }
        return ref;
    }

    /**
     * Call a function using according the function options.
     * @param entity to be sent to the function
     * @param functionName name of the function to be called
     * @param functionOpts options to configure the request
     */
    public callFunction(entity: any, functionName: string, functionOpts: FunctionOptions): Observable<any> {
        if (functionOpts.type === 'callable') {
            return httpsCallableData(this.functions, functionName, {
                timeout: 60000 * 60, // 1minute
            })(entity).pipe(
                catchError((error) => {
                    const message = error.error ? error.error.message : error.message;
                    this.fireLogging.sendErrorLog(`An error occurred calling the function ${ functionName }, details: ${ message || JSON.stringify(error) }`);
                    throw {error};
                })
            );
        } else {
            let httpRequest;
            if (functionOpts.httpMethod === 'GET') {
                httpRequest = new HttpRequest(functionOpts.httpMethod, FIREBASE_URL_FUNCTIONS);
            } else if (functionOpts.httpMethod === 'DELETE') {
                httpRequest = new HttpRequest(functionOpts.httpMethod, FIREBASE_URL_FUNCTIONS);
            } else {
                httpRequest = new HttpRequest(functionOpts.httpMethod, FIREBASE_URL_FUNCTIONS, entity);
            }
            return this.http.request(httpRequest).pipe(
                catchError((error) => {
                    const message = error.error ? error.error.message : error.message;
                    this.fireLogging.sendErrorLog(`An error occurred calling the function ${ functionName }, details: ${ message || JSON.stringify(error) }`);
                    throw {error};
                })
            );
        }
    }

    protected buidlFirebaseOrderFilter(ref, filter: FilterModel) {
        if (filter.sort && filter.column && !filter.dateRange) {
            ref = ref.orderBy(filter.column, filter.sort);
        }
        return ref;
    }

    protected buildFirebaseFilter(ref, filter: FilterModel) {
        ref = this.buidlFirebaseOrderFilter(ref, filter);
        if (filter.dateRange) {
            ref = ref.orderBy(filter.dateRange.fieldPath).startAt(filter.dateRange.startDate).endAt(filter.dateRange.endDate);
        }
        if (filter.search) {
            ref = ref.startAt(filter.search).endAt(`${ filter.search }\uf8ff`);
        }
        if (filter.pageSize) {
            ref = ref.limit(filter.pageSize);
        }
        if (filter.startAfter) {
            ref = ref.startAfter(filter.startAfter);
        }
        ref = this.buildFirebaseFiltersClauses(ref, filter);
        return ref;
    }

}

