import firebase from 'firebase/compat';
import WhereFilterOp = firebase.firestore.WhereFilterOp;

export class FilterModel {
    public column?: string;
    public pageSize?: number;
    public startAfter?: any;
    public dateRange?: DateRangeModel;
    public sort?: 'asc' | 'desc';
    public search?: string;
    public clauses?: ClauseModel[] = [];
}

export class DateRangeModel {
    public fieldPath: string;
    public startDate: Date;
    public endDate: Date;
}

export class ClauseModel {
    public fieldPath: string;
    public opStr: WhereFilterOp;
    public value: any;
}
