import {Injectable} from '@angular/core';
import {StorageService} from 'src/app/core/services/storage.service';
import {ALLOW_COOKIES_LOCAL_STORAGE_KEY, LANG_KEY_LOCAL_STORAGE_KEY} from 'src/app/app.constants';
import {CookieSettingsModel} from '../../shared/models/cookieSettings.model';
import {CookieTypesEnum} from '../../shared/enums/cookie-types.enum';
import {TranslateService} from '@ngx-translate/core';
import {capitalize} from 'lodash';

@Injectable({providedIn: 'root'})
export class AppService {

    constructor(private storageService: StorageService, private translateService: TranslateService) {
    }

    getCookiesAllowed(): CookieSettingsModel {
        const cookie = this.storageService.get(ALLOW_COOKIES_LOCAL_STORAGE_KEY);
        if (cookie) {
            if (!Object.prototype.hasOwnProperty.call(cookie, 'cookieVersion') || cookie.cookieVersion !== CookieSettingsModel.version) {
                return null;
            }
        }
        return cookie;
    }

    allowCookies(cookieSettings: CookieSettingsModel) {
        this.storageService.save(ALLOW_COOKIES_LOCAL_STORAGE_KEY, cookieSettings);
    }

    isCookieIsAllowed(cookieType: CookieTypesEnum) {
        const cookieSettings = this.storageService.get(ALLOW_COOKIES_LOCAL_STORAGE_KEY);
        return cookieSettings ? cookieSettings[`allow${capitalize(cookieType.toString())}`] : false;
    }

    reprocessCookie() {
        const cookieSettings = this.getCookiesAllowed();
        if (cookieSettings.allowPreferences) {
            if (!this.storageService.get(LANG_KEY_LOCAL_STORAGE_KEY)) {
                this.storageService.save(LANG_KEY_LOCAL_STORAGE_KEY, this.translateService.currentLang);
            }
        } else {
            this.storageService.remove(LANG_KEY_LOCAL_STORAGE_KEY);
        }
        this.reloadPage();
    }

    /**
     * This function is used by the unit tests. The jasmine cannot call the 'window.reload' directly.
     * @private Reload page
     */
    private reloadPage() {
        window.location.reload();
    }
}
