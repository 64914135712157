import * as crypto from 'crypto-js';
import {environment} from '../../../environments/environment';

export const encrypt = (payload): string => {
    if (payload) {
        return crypto.AES.encrypt(payload, environment.cryptoSecretKey.trim()).toString();
    }
};

export const decrypt = (payload): string => {
    if (payload) {
        return crypto.AES.decrypt(payload, environment.cryptoSecretKey.trim()).toString(crypto.enc.Utf8);
    }
};
