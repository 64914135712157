import { Injectable } from '@angular/core';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import { of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { CREATE_REPORT } from 'src/app/app.constants';
import { UAParser } from 'ua-parser-js';

@Injectable({
    providedIn: 'root',
})
export class FireLoggingService {
    private userAgent: UAParser;

    constructor(private functions: Functions) {
        this.userAgent = new UAParser();
    }

    sendErrorLog(description: string) {
        const report = this.buildReport(description, 'ERROR');
        this.buildRequest(report).subscribe();
    }

    sendDefaultLog(description: string) {
        const report = this.buildReport(description, 'DEFAULT');
        this.buildRequest(report).subscribe();
    }

    sendDebugLog(description: string) {
        const report = this.buildReport(description, 'DEBUG');
        this.buildRequest(report).subscribe();
    }

    sendInfoLog(description: string) {
        const report = this.buildReport(description, 'INFO');
        this.buildRequest(report).subscribe();
    }

    sendNoticeLog(description: string) {
        const report = this.buildReport(description, 'NOTICE');
        this.buildRequest(report).subscribe();
    }

    sendWarningLog(description: string) {
        const report = this.buildReport(description, 'WARNING');
        this.buildRequest(report).subscribe();
    }

    sendCriticalLog(description: string) {
        const report = this.buildReport(description, 'CRITICAL');
        this.buildRequest(report).subscribe();
    }

    sendAlertLog(description: string) {
        const report = this.buildReport(description, 'ALERT');
        this.buildRequest(report).subscribe();
    }

    sendEmergencyLog(description: string) {
        const report = this.buildReport(description, 'EMERGENCY');
        this.buildRequest(report).subscribe();
    }

    buildRequest(report) {
        return httpsCallableData(this.functions, CREATE_REPORT)(report)
            .pipe(
                catchError((err) => {
                    console.error(
                        'Unable to record error details, please contact administrator'
                    );
                    console.error(err);
                    return of(); // empty observable;
                }),
                take(1)
            );
    }

    buildReport(
        description: string,
        severity:
            | 'DEFAULT'
            | 'DEBUG'
            | 'INFO'
            | 'NOTICE'
            | 'WARNING'
            | 'ERROR'
            | 'CRITICAL'
            | 'ALERT'
            | 'EMERGENCY'
    ) {
        return {
            source: 'PORTAL',
            description,
            severity,
            sourceTimestamp: this.currentTimestamp,
            userAgent: `${this.userAgent.getBrowser().name} - ${
                this.userAgent.getBrowser().version
            }`,
            trace: window.location.href,
            currentUser: {},
        };
    }

    private get currentTimestamp() {
        return new Date().getTime();
    }
}
