import { DocumentReference } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat';
import Timestamp = firebase.firestore.Timestamp;

export class ChangeHistoryMappedModel {
    public action: string;
    public collection: string;
    public reference: DocumentReference;
    public referenceId: string;
    public data: any[];

    constructor(data?: ChangeHistoryModel) {
        if (data) {
            const list = [];
            Object.keys(data.currentData).forEach((key) => {
                list.push({
                    reference: data.documentRef,
                    property: key,
                    newValue: data.currentData[key],
                    previousValue: data.previousData[key],
                });
            });
            this.action = data.action;
            this.collection = data.collection;
            this.reference = data.documentRef;
            this.referenceId = data.documentRef.id;
            this.data = list;
        }
    }
}

export class ChangeHistoryModel {
    public action: string;
    public collection: string;
    public companyId: string;
    public createdAt: Timestamp;
    public createdBy: string;
    public createdFrom: string;
    public currentData: any;
    public previousData: any;
    public documentRef: DocumentReference;
    public timestamp: Timestamp;
    public userId: string;

    constructor(data?: any) {
        if (data) {
            this.action = data.action;
            this.collection = data.collection;
            this.companyId = data.companyId;
            this.createdAt = data.createdAt;
            this.createdBy = data.createdBy;
            this.createdFrom = data.createdFrom;
            this.currentData = data.currentData;
            this.previousData = data.previousData;
            this.documentRef = data.documentRef;
            this.timestamp = data.timestamp;
            this.userId = data.userId;
        }
    }
}
