import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Functions } from '@angular/fire/functions';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, share, take } from 'rxjs/operators';
import { CHANGES_HISTORY_COLLECTION } from 'src/app/app.constants';
import { BaseService } from 'src/app/core/abstractions/base-service';
import { ChangeHistoryMappedModel, ChangeHistoryModel } from '../../shared/models/change-history.model';
import { FireLoggingService } from './fire-logging.service';

@Injectable({providedIn: 'root'})
export class ChangeHistoryService extends BaseService<any> {
    private changeHistoryDataObs: Map<string, Observable<any>> = new Map();

    constructor(
        private activatedRoute: ActivatedRoute,
        protected firestore: AngularFirestore,
        protected http: HttpClient,
        protected functions: Functions,
        protected fireLogging: FireLoggingService
    ) {
        super(firestore, http, functions, fireLogging);
    }

    /**
     * Get change history data resolved to show in the historical data component (clock icons in the inputs);
     *
     * @returns The change history found;
     * @param changeHistoryId The id of the change history to fetch;
     */
    getChangeHistoryData(changeHistoryId: string): Observable<ChangeHistoryMappedModel> {
        return this.getChangeHistoryById(changeHistoryId).pipe(map((changeHistory: ChangeHistoryModel) => {
                return new ChangeHistoryMappedModel(changeHistory);
            })
        );
    }

    private getChangeHistoryById(changeHistoryId: string) {
        if (!this.changeHistoryDataObs.has(changeHistoryId)) {
            this.changeHistoryDataObs.set(
                changeHistoryId,
                this.getById(
                    changeHistoryId,
                    CHANGES_HISTORY_COLLECTION
                ).pipe(
                    take(1),
                    share() // Share is used to prevent all fields from calling firebase api again
                )
            );
        }
        return this.changeHistoryDataObs.get(changeHistoryId);
    }

    get changeHistoryId(): Observable<string> {
        return this.activatedRoute.queryParams.pipe(map((queryData: any) => queryData.changeHistoryId));
    }

}
