import { CompanyModel } from './company.model';

export class UserModel {
    public id?: string;
    public firstName?: string;
    public lastName?: string;
    public email?: string;
    public lang?: string;
    public companyId?: string;
    public finsteinUser?: boolean;
    public default?: boolean;
    public roles?: any[] = [];
    public notificationSubscriptions?: any[];
    public verified?: boolean;
    public createdAt?: Date;
    public permissions?: any[] = [];
    public permissionsReadOnly?: string[] = [];
    public readOnly?: string;
    public status?: string;
    public companyResponsible?: boolean;
    public finsteinHelper?: boolean;

    constructor(
        id?: string,
        firstName?: string,
        lastName?: string,
        email?: string,
        lang?: string,
        roles?: any[],
        finsteinUser?: boolean,
        notificationSubscriptions?: any[],
        verified = false,
        companyResponsible?: boolean,
        finsteinHelper?: boolean
    ) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.lang = lang;
        this.finsteinUser = finsteinUser ? finsteinUser : false;
        this.roles = roles ? roles : [];
        this.notificationSubscriptions = notificationSubscriptions
            ? notificationSubscriptions
            : [];
        this.verified = verified;
        this.companyResponsible = companyResponsible;
        this.finsteinHelper = finsteinHelper ? finsteinHelper : false;
    }
}

export class LoggedInUser {
    public id?: string;
    public firstName?: string;
    public lastName?: string;
    public email?: string;
    public lang?: string;
    public finsteinUser?: boolean;
    public finsteinHelper?: boolean;
    public roles?: any[] = [];
    public notificationSubscriptions?: any[];
    public verified?: boolean;
    public companyId?: string;
    public companyName?: string;
    public companyStatus?: string;
    public companyConfig?: {
        isAppAvailableOneMonthBeforeContractDate?: boolean;
    };
    public createdBy?: Date;
    public permissions?: string[] = [];
    public permissionsReadOnly?: string[] = [];
    public status?: string;
    public settings: UserSettings;
    public companyResponsible?: boolean;
    public defaultPermissions?: string[];
    public company?: CompanyModel;
    public phone?: string;
    public isDemo?: boolean;
    public debugImportFileMode?: boolean;
    public inactivitySettings: any;
    public portalNotifications?: any
}

export class UserSettings {
    notification: {
        sound: boolean;
    };
    tasksOrder: any;
}
