import {registerLocaleData} from '@angular/common';
import {EventEmitter, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import moment from 'moment';
import {LANG_KEY_LOCAL_STORAGE_KEY, languages} from '../../app.constants';
import {StorageService} from './storage.service';
import {AppService} from './app.service';
import {CookieTypesEnum} from '../../shared/enums/cookie-types.enum';

@Injectable({ providedIn: 'root' })
export class LanguageService {

    languageChangeEmitter: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private translateService: TranslateService,
        storageService: StorageService,
        private appService: AppService
    ) {
        this.translateService.onLangChange.subscribe(({ lang }) => {
            if (this.appService.isCookieIsAllowed(CookieTypesEnum.PREFERENCES)) {
                storageService.save(LANG_KEY_LOCAL_STORAGE_KEY, lang);
            }
            document.querySelector('html').lang = lang;
            moment.locale(lang);
            // TODO: Fix this
            // import(
            //     /* webpackInclude: /(en|de)\.js$/ */
            //     `@angular/common/locales/${lang}.js`
            // ).then((e) => registerLocaleData(e.default));
        });
    }

    getAll(): string[] {
        return languages.map((item) => item.key);
    }

    changeLanguage(langKey) {
        return this.translateService.use(langKey);
    }

    getLocalCurrentLanguage() {
        return this.translateService.currentLang;
    }

}
