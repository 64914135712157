import { Injectable, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import {
    RESP_CODE_ABORTED,
    RESP_CODE_ALREADY_EXISTS,
    RESP_CODE_CANCELLED,
    RESP_CODE_DATA_LOSS,
    RESP_CODE_DEADLINE_EXCEEDED,
    RESP_CODE_FAILED_PRECONDITION,
    RESP_CODE_INTERNAL,
    RESP_CODE_INVALID_ARGUMENT,
    RESP_CODE_NOT_FOUND,
    RESP_CODE_OK,
    RESP_CODE_OUT_OF_RANGE,
    RESP_CODE_PERMISSION_DENIED,
    RESP_CODE_RESOURCE_EXHAUSTED,
    RESP_CODE_UNAUTHORIZED,
    RESP_CODE_UNAVAILABLE,
    RESP_CODE_UNIMPLEMENTED,
    RESP_CODE_UNKNOWN,
} from 'src/app/app.constants';

@Injectable({providedIn: 'root'})
export class ToastService {

    constructor(
        private translateService: TranslateService,
        private toastService: ToastrService,
        private ngZone: NgZone
    ) {
    }

    showToast(code: string, message: string) {
        if (code === RESP_CODE_OK) {
            this.success(message);
        } else if (
            code === RESP_CODE_UNAUTHORIZED ||
            code === RESP_CODE_UNKNOWN ||
            code === RESP_CODE_INTERNAL ||
            code === RESP_CODE_INVALID_ARGUMENT ||
            code === RESP_CODE_DEADLINE_EXCEEDED ||
            code === RESP_CODE_PERMISSION_DENIED ||
            code === RESP_CODE_RESOURCE_EXHAUSTED ||
            code === RESP_CODE_ABORTED ||
            code === RESP_CODE_OUT_OF_RANGE ||
            code === RESP_CODE_DATA_LOSS ||
            code === RESP_CODE_ALREADY_EXISTS
        ) {
            this.error(message);
        } else if (
            code === RESP_CODE_CANCELLED ||
            code === RESP_CODE_FAILED_PRECONDITION
        ) {
            this.info(message);
        } else if (
            code === RESP_CODE_NOT_FOUND ||
            code === RESP_CODE_UNIMPLEMENTED ||
            code === RESP_CODE_UNAVAILABLE
        ) {
            this.warning(message);
        }
    }

    success(text: string) {
        this.ngZone.run(() => this.toastService.success(this.translateService.instant(text),
            this.translateService.instant('operations.success.title')));
    }

    error(text: string, interpolateParams?) {
        this.ngZone.run(() => this.toastService.error(this.translateService.instant(text, interpolateParams),
            this.translateService.instant('operations.error.title', interpolateParams)));
    }

    info(text: string, interpolateParams?) {
        this.ngZone.run(() => this.toastService.info(this.translateService.instant(text, interpolateParams),
            this.translateService.instant('operations.info.title', interpolateParams)));
    }

    warning(text: string, interpolateParams?) {
        this.ngZone.run(() => this.toastService.warning(this.translateService.instant(text, interpolateParams),
            this.translateService.instant('operations.warning.title', interpolateParams)));
    }

    insertSuccess() {
        this.ngZone.run(() => this.toastService.success(
            this.translateService.instant('operations.insert.success'),
            this.translateService.instant('operations.insert.title'))
        );
    }

    insertError() {
        this.translateService.get('operations.insert.error').subscribe((message: string) => {
            this.error(message);
        });
    }

    updateSuccess() {
        this.ngZone.run(() => this.toastService.success(
            this.translateService.instant('operations.update.success'),
            this.translateService.instant('operations.update.title'))
        );
    }

    updateError() {
        this.translateService.get('operations.update.error').subscribe((message: string) => {
            this.error(message);
        });
    }

    notFoundError() {
        this.translateService.get('operations.get.not-found').subscribe((message: string) => {
            this.error(message);
        });
    }

    enableSuccess() {
        this.translateService.get('operations.enable.success').subscribe((message: string) => {
            this.success(message);
        });
    }

    enableError() {
        this.translateService.get('operations.enable.error').subscribe((message: string) => {
            this.error(message);
        });
    }

    disableSuccess() {
        this.translateService.get('operations.disable.success').subscribe((message: string) => {
            this.success(message);
        });
    }

    disableError() {
        this.translateService.get('operations.disable.error').subscribe((message: string) => {
            this.error(message);
        });
    }

    validationError() {
        this.translateService.get('operations.validation.error').subscribe((message: string) => {
            this.error(message);
        });
    }

    downloadError() {
        this.translateService.get('operations.download.error').subscribe((message: string) => {
            this.error(message);
        });
    }
}

