import {Injectable} from '@angular/core';
import {decrypt, encrypt} from '../util/crypto.util';
import { FireLoggingService } from './fire-logging.service';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    constructor(private fireLogginService: FireLoggingService) {
    }

    save(key: string, data: any) {
        try {
            localStorage.setItem(key, encrypt(JSON.stringify(data)));
        } catch (e) {
            this.fireLogginService.sendWarningLog(e.message || e);
            console.error(e);
        }
    }

    get(key: string) {
        try {
            return JSON.parse(decrypt(localStorage.getItem(key)));
        } catch (e) {
            return null;
        }
    }

    remove(key: string) {
        localStorage.removeItem(key);
    }

}
