export class CookieSettingsModel {
    static readonly version = '1.0';

    public cookieVersion: string;
    public allowNecessary: boolean;
    public allowPreferences?: boolean;
    public allowAnalytics?: boolean;
    public allowMarketing?: boolean;
    public allowUnclassified?: boolean;

    constructor() {
        this.allowNecessary = true;
        this.allowPreferences = false;
        this.allowAnalytics = false;
        this.allowMarketing = false;
        this.allowUnclassified = false;
        this.cookieVersion = CookieSettingsModel.version;
    }

    allowAllCookies() {
        this.allowNecessary = true;
        this.allowPreferences = true;
        this.allowAnalytics = true;
        this.allowMarketing = true;
        this.allowUnclassified = true;
    }
}
